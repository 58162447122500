// Import before vue components
// Vendors
import './assets/vendors/mdi/css/materialdesignicons.min.css'
import './assets/vendors/css/vendor.bundle.base.css'

// Node Modules
import 'flatpickr/dist/flatpickr.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-select/src/scss/vue-select.scss'
import 'vue-cal/dist/vuecal.css'

// SCS/CSS App Styles
import './assets/css/style.css'
import './assets/scss/custom.scss'

// Import vue and modules
import Vue from 'vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueFlatPickr from 'vue-flatpickr-component'
import VueSweetalert2 from 'vue-sweetalert2'
import VueEvents from 'vue-events'
import VueLodash from 'vue-lodash'
import vSelect from 'vue-select'
import Hotjar from 'vue-hotjar'
import lodash from 'lodash'
import VueWait from 'vue-wait'
import router from './router'
import store from './store'
import './registerServiceWorker'
import '@/filters/index'

Vue.config.devtools = true

Vue.use(VueMoment, {
  moment
})
Vue.use(BootstrapVue)
Vue.use(VueLodash, { name: 'custom', lodash: lodash })
Vue.use(VueSweetalert2)
Vue.use(VueFlatPickr)
Vue.use(VueEvents)
Vue.use(Hotjar, {
  id: '1981029',
  isProduction: (process.env.NODE_ENV === 'production')
})
Vue.component('datetime', VueFlatPickr)
Vue.component('v-select', vSelect)
Vue.use(VueWait)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  wait: new VueWait(),
  render: h => h(App)
}).$mount('#app')
