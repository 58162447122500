import { deleteAttachment } from '@/api/clientAttachment.api'

// State object
const initialState = () => ({
  modalCreate: false
})

const state = initialState()

// Getter functions
const getters = {
  modalCreate (state) {
    return state.modalCreate
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async delete ({ commit }, payload) {
    try {
      await deleteAttachment(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  TOGGLE_MODAL_CREATE (state) {
    state.modalCreate = !state.modalCreate
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
