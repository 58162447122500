import axios from 'axios'
import VueCookie from 'vue-cookie'
import store from '../store/index'
import Vue from 'vue'

Vue.use(VueCookie)

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL || 'https://licenapi.veratsys.com'

const httpClient = axios.create({
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
  timeout: 8000
})
const UNAUTHORIZED = 401
const NOTFOUND = 404

httpClient.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response
    if (status === UNAUTHORIZED) {
      Vue.cookie.delete('user')
      store.commit('Authentication/RESET')
      location.href = location.origin + '/login'
    }
    if (status === NOTFOUND) {
      // location.href = location.origin + '/no-encontrado'
    }
    return Promise.reject(error)
  }
)

export default httpClient
