import { getAllEvents, getAllCalendarEvents, createEvent, updateEvent, deleteEvent, getEvent } from '@/api/agenda.api'

// State object
const initialState = () => ({
  events: [],
  createAgendaAppointmentModal: false,
  editAgendaAppointmentModal: false,
  createAgendaTaskModal: false,
  editAgendaTaskModal: false,
  createAgendaAudienceModal: false,
  editAgendaAudienceModal: false
})

const state = initialState()

// Getter functions
const getters = {
  events (state) {
    return state.events
  },
  createAgendaAppointmentModal (state) {
    return state.createAgendaAppointmentModal
  },
  editAgendaAppointmentModal (state) {
    return state.editAgendaAppointmentModal
  },
  createAgendaTaskModal (state) {
    return state.createAgendaTaskModal
  },
  editAgendaTaskModal (state) {
    return state.editAgendaTaskModal
  },
  createAgendaAudienceModal (state) {
    return state.createAgendaAudienceModal
  },
  editAgendaAudienceModal (state) {
    return state.editAgendaAudienceModal
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async getAll ({ commit }) {
    try {
      const response = await getAllEvents()
      commit('SET_EVENTS', response.data)
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getAllCalendarEvents ({ commit }) {
    try {
      const response = await getAllCalendarEvents()
      commit('SET_EVENTS', response.data)
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchEvent ({ commit }, payload) {
    try {
      const response = await getEvent(payload)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async create ({ commit }, payload) {
    try {
      const response = await createEvent(payload)
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update ({ commit }, payload) {
    try {
      await updateEvent(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async delete ({ commit }, payload) {
    try {
      await deleteEvent(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_EVENTS (state, payload) {
    state.events = payload.data
  },
  TOGGLE_CREATE_AGENDA_APPOINTMENT_MODAL (state, payload) {
    if (payload) {
      state.createAgendaAppointmentModal = payload
    } else {
      state.createAgendaAppointmentModal = !state.createAgendaAppointmentModal
    }
  },
  TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL (state, payload) {
    if (payload) {
      state.editAgendaAppointmentModal = payload
    } else {
      state.editAgendaAppointmentModal = !state.editAgendaAppointmentModal
    }
  },
  TOGGLE_CREATE_AGENDA_TASK_MODAL (state, payload) {
    if (payload) {
      state.createAgendaTaskModal = payload
    } else {
      state.createAgendaTaskModal = !state.createAgendaTaskModal
    }
  },
  TOGGLE_EDIT_AGENDA_TASK_MODAL (state, payload) {
    if (payload) {
      state.editAgendaTaskModal = payload
    } else {
      state.editAgendaTaskModal = !state.editAgendaTaskModal
    }
  },
  TOGGLE_CREATE_AGENDA_AUDIENCE_MODAL (state, payload) {
    if (payload) {
      state.createAgendaAudienceModal = payload
    } else {
      state.createAgendaAudienceModal = !state.createAgendaAudienceModal
    }
  },
  TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL (state, payload) {
    if (payload) {
      state.editAgendaAudienceModal = payload
    } else {
      state.editAgendaAudienceModal = !state.editAgendaAudienceModal
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
