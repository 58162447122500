import httpClient from './httpClient'

const END_POINT = '/api/notifications'

const getNotifications = () => httpClient.get(END_POINT)
const storeNotification = (payload) => httpClient.post(END_POINT)
const makeAllNotificationsRead = (payload) => httpClient.post(END_POINT + '/mark-all-read')
const makeReadNotification = (payload) => httpClient.patch(END_POINT + '/' + payload.id + '/read')
const dismissNotification = (payload) => httpClient.post(END_POINT + '/' + payload.id + '/dismiss')

export {
  getNotifications,
  storeNotification,
  makeAllNotificationsRead,
  makeReadNotification,
  dismissNotification
}
