import httpClient from './httpClient'

const END_POINT = '/api/invoices'

const getAllInvoices = () => httpClient.get(END_POINT)
const getAllInvoicesDropdown = (payload) => httpClient.get(END_POINT + '/get/dropdown', { params: payload })
const showInvoice = (payload) => httpClient.get(END_POINT + '/' + payload.id)
const createInvoices = (payload) => httpClient.post(END_POINT, payload)
const updateInvoices = (payload) => httpClient.put(END_POINT + '/' + payload.id, payload)
const deleteInvoices = (payload) => httpClient.delete(END_POINT + '/' + payload.id)
const setInvoiceStatusPaid = (payload) => httpClient.put(END_POINT + '/set_status_paid/' + payload.invoice.id, {
  payment_method: payload.payment.payment_method,
  amount: payload.payment.amount
})

export {
  getAllInvoices,
  getAllInvoicesDropdown,
  showInvoice,
  createInvoices,
  updateInvoices,
  deleteInvoices,
  setInvoiceStatusPaid
}
