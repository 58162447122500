import httpClient from './httpClient'

const END_POINT = '/api/providers'

const getAllProviders = () => httpClient.get(END_POINT)
const getProviders = () => httpClient.get(END_POINT + '/get/dropdown')
const createProvider = (payload) => httpClient.post(END_POINT, payload)
const updateProvider = (payload) => httpClient.put(END_POINT + '/' + payload.id, payload)
const deleteProvider = (payload) => httpClient.delete(END_POINT + '/' + payload.id)

export {
  getAllProviders,
  getProviders,
  createProvider,
  updateProvider,
  deleteProvider
}
