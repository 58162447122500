import httpClient from './httpClient'

const END_POINT = '/api/records'

const getAllRecords = (payload) => httpClient.get(END_POINT, { params: payload })
const getAllRecordsDropdown = (payload) => httpClient.get(END_POINT + '/get/dropdown', { params: payload })
const getRecord = (payload) => httpClient.get(END_POINT + '/' + payload.id)
const createRecord = (payload) => httpClient.post(END_POINT, payload)
const deleteRecord = (payload) => httpClient.delete(END_POINT + '/' + payload.id)
const updateRecord = (payload) => httpClient.put(END_POINT + '/' + payload.id, payload)

export {
  getAllRecords,
  getAllRecordsDropdown,
  getRecord,
  createRecord,
  deleteRecord,
  updateRecord
}
