import { createInvoices, updateInvoices, deleteInvoices, showInvoice, getAllInvoicesDropdown, setInvoiceStatusPaid } from '@/api/invoice.api'

// State object
const initialState = () => ({
  modalCreate: false,
  modalUpdate: false,
  invoices_dropdown: []
})

const state = initialState()

// Getter functions
const getters = {
  modalCreate (state) {
    return state.modalCreate
  },
  modalUpdate (state) {
    return state.modalUpdate
  },
  invoices_dropdown (state) {
    return state.invoices_dropdown
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async getInvoicesDropdown ({ commit }, payload) {
    try {
      const response = await getAllInvoicesDropdown(payload)
      commit('SET_INVOICES_DROPDOWN', response.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async create ({ commit }, payload) {
    try {
      const response = await createInvoices(payload)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update ({ commit }, payload) {
    try {
      await updateInvoices(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async setStatusPaid ({ commit }, payload) {
    try {
      await setInvoiceStatusPaid(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async show ({ commit }, payload) {
    try {
      const response = await showInvoice(payload)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async delete ({ commit }, payload) {
    try {
      await deleteInvoices(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_INVOICES_DROPDOWN (state, payload) {
    state.invoices_dropdown = payload.filter(function (item, $key) {
      return item.balance > 0
    })
  },
  TOGGLE_MODAL_CREATE (state, payload) {
    if (payload) {
      state.modalCreate = payload
    } else {
      state.modalCreate = !state.modalCreate
    }
  },
  TOGGLE_MODAL_UPDATE (state, payload) {
    if (payload) {
      state.modalUpdate = payload
    } else {
      state.modalUpdate = !state.modalUpdate
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
