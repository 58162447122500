import httpClient from './httpClient'

const END_POINT = '/api/courts'

const getAllCourtsDropdown = (payload) => httpClient.get(END_POINT + '/dropdown', {
  params: {
    exclude: payload ? payload.exclude : ''
  }
})
const getAllSubjectsDropdown = () => httpClient.get(END_POINT + '/subjects/dropdown')
const createCourt = (payload) => httpClient.post(END_POINT, payload)
const updateCourt = (payload) => httpClient.put(END_POINT + '/' + payload.id, payload)

export {
  getAllCourtsDropdown,
  createCourt,
  updateCourt,
  getAllSubjectsDropdown
}
