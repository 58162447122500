import httpClient from './httpClient'

const END_POINT = '/api/expenses'

const getAllExpenses = () => httpClient.get(END_POINT)
const getExpense = (payload) => httpClient.get(END_POINT + '/' + payload.id)
const createExpenses = (payload) => httpClient.post(END_POINT, payload)
const updateExpenses = (payload) => httpClient.put(END_POINT + '/' + payload.id, payload)
const deleteExpenses = (payload) => httpClient.delete(END_POINT + '/' + payload.id)

export {
  getAllExpenses,
  getExpense,
  createExpenses,
  updateExpenses,
  deleteExpenses
}
