import httpClient from './httpClient'

const END_POINT = '/api/agenda'

const getAllEvents = () => httpClient.get(END_POINT)
const getAllCalendarEvents = () => httpClient.get(END_POINT + '/calendar/events')
const getEvent = (payload) => httpClient.get(END_POINT + '/' + payload.id)
const createEvent = (payload) => httpClient.post(END_POINT, payload)
const updateEvent = (payload) => httpClient.put(END_POINT + '/' + payload.id, payload)
const deleteEvent = (payload) => httpClient.delete(END_POINT + '/' + payload.id)

export {
  getAllEvents,
  getAllCalendarEvents,
  createEvent,
  updateEvent,
  deleteEvent,
  getEvent
}
