import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['Authentication/loggedIn']) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters['Authentication/loggedIn']) {
    next()
  } else {
    next('/login')
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Dashboard.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/agenda',
    name: 'agenda',
    component: () => import('@/views/Agenda.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/agenda/calendar',
    name: 'agenda_calendar',
    component: () => import('@/views/AgendaCalendar.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/agenda/audience/:id/report',
    name: 'agenda_report',
    props: true,
    component: () => import('@/views/AgendaAudienceReport.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/expenses',
    name: 'expenses',
    component: () => import('@/views/Expense.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/expenses/:id/receipt',
    name: 'expense_receipt',
    props: true,
    component: () => import('@/views/ExpenseReceipt.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/Invoice.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/invoice/:id/receipt',
    name: 'invoice_receipt',
    props: true,
    component: () => import('@/views/InvoiceReceipt.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/bills',
    name: 'bills',
    component: () => import('@/views/Bill.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/bill/:id/receipt',
    name: 'bill_receipt',
    props: true,
    component: () => import('@/views/BillReceipt.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@/views/Payment.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/payment/:id/receipt',
    name: 'payment_receipt',
    props: true,
    component: () => import('@/views/PaymentReceipt.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/finance/report',
    name: 'finance_report',
    component: () => import('@/views/FinanceReport.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('@/views/Client.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/record/client/:client_request',
    name: 'client_records',
    props: true,
    component: () => import('@/views/Record.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/agenda/client/:client_request',
    name: 'client_agenda',
    props: true,
    component: () => import('@/views/Agenda.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/User.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/courts',
    name: 'courts',
    component: () => import('@/views/Court.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/instances',
    name: 'instances',
    component: () => import('@/views/Instance.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/records',
    name: 'records',
    component: () => import('@/views/Record.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/record/:id/details',
    name: 'record_details',
    props: true,
    component: () => import('@/views/RecordDetails.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/record/label/:id',
    name: 'record_print_label',
    props: true,
    component: () => import('@/views/RecordPrintLabel.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/record/labels',
    name: 'record_print_labels',
    props: true,
    component: () => import('@/views/RecordPrintLabels.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/Role.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/providers',
    name: 'providers',
    component: () => import('@/views/Provider.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginUser.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/RegisterUser.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/LogoutUser.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/recover-password',
    name: 'recover_password',
    component: () => import('@/views/ResetPasswordUser.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/update-password/:token',
    name: 'update_password',
    component: () => import('@/views/UpdatePasswordUser.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/components/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

export default router
