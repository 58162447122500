import { getAllRoles, storeRole, updateRole, updateRolePermission } from '@/api/role.api'

// State object
const initialState = () => ({
  role: false,
  roles: [],
  modalUpdate: false,
  modalCreate: false
})

const state = initialState()

// Getter functions
const getters = {
  modalCreate (state) {
    return state.modalCreate
  },
  modalUpdate (state) {
    return state.modalUpdate
  },
  roles (state) {
    return state.roles
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async getAll ({ commit }) {
    try {
      const response = await getAllRoles()
      commit('SET_ROLES', response.data)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async create ({ commit }, payload) {
    try {
      const response = await storeRole(payload)
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update ({ commit }, payload) {
    try {
      await updateRole(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async updatePermission ({ commit }, payload) {
    try {
      await updateRolePermission(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  TOGGLE_MODAL_UPDATE (state, payload) {
    if (payload) {
      state.modalUpdate = payload
    } else {
      state.modalUpdate = !state.modalUpdate
    }
  },
  TOGGLE_MODAL_CREATE (state, payload) {
    if (payload) {
      state.modalCreate = payload
    } else {
      state.modalCreate = !state.modalCreate
    }
  },
  SET_ROLES (state, payload) {
    state.roles = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
