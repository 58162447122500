import httpClient from './httpClient'

const END_POINT = '/api/roles'

const getAllRoles = () => httpClient.get(END_POINT + '/get/dropdown')
const storeRole = (payload) => httpClient.post(END_POINT, payload)
const updateRole = (payload) => httpClient.put(END_POINT + '/' + payload.id, payload)
const updateRolePermission = (payload) => httpClient.put(END_POINT + '/' + payload.role + '/update/permission', payload)

export {
  updateRolePermission,
  getAllRoles,
  storeRole,
  updateRole
}
