import { getNotifications, storeNotification, makeAllNotificationsRead, makeReadNotification, dismissNotification } from '@/api/notification.api'

// State object
const initialState = () => ({
  notifications: []
})

const state = initialState()

// Getter functions
const getters = {
  notifications (state) {
    return state.notifications
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async get ({ commit }) {
    try {
      const response = await getNotifications()
      commit('SET_NOTIFICATIONS', response.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async test ({ commit }) {
    try {
      const response = await storeNotification()
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async makeAllRead ({ commit }) {
    try {
      const response = await makeAllNotificationsRead()
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async makeRead ({ commit }, payload) {
    try {
      const response = await makeReadNotification(payload)
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async dismiss ({ commit }, payload) {
    try {
      const response = await dismissNotification(payload)
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_NOTIFICATIONS (state, payload) {
    state.notifications = payload.notifications.map(({ id, data, created }) => {
      return {
        id: id,
        title: data.title,
        body: data.body,
        created: created,
        action_url: data.action_url
      }
    })
  },
  APPEND_NOTIFICATION (state, payload) {
    state.notifications.unshift(payload)
  },
  MARK_AS_READ (state, payload) {
    const index = state.notifications.findIndex(n => n.id === payload)
    if (index > -1) {
      state.notifications.splice(index, 1)
    }
  },
  MARK_ALL_READ (state, payload) {
    state.notifications = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
