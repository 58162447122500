import Vue from 'vue'

Vue.filter('truncate', function (value) {
  if (!value) return ''
  const length = 20
  const ending = '...'
  if (value.length > length) {
    return value.substring(0, length - ending.length) + ending
  }
  return value
})

Vue.filter('formatMoney', function (value) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  return formatter.format(value)
})

Vue.filter('getInvoiceBalance', function (value) {
  const balance = Number(value.total)
  let payments = 0
  value.payments.map((payment) => {
    payments = payments + Number(payment.amount)
  })
  return (balance - payments) > 0 ? (balance - payments) : 0
})

Vue.filter('getInvoicePayments', function (value) {
  let payments = 0
  value.map((payment) => {
    payments = payments + Number(payment.amount)
  })
  return payments
})

Vue.filter('lowercase', function (value) {
  if (typeof value === 'undefined') {
    return ''
  } else if (value === null) {
    return ''
  } else if (typeof value === 'string') {
    return value.toString().toLowerCase()
  } else {
    return value
  }
})

Vue.filter('uppercase', function (value) {
  if (typeof value === 'undefined') {
    return ''
  } else if (value === null) {
    return ''
  } else if (typeof value === 'string') {
    return value.toString().charAt(0).toUpperCase() + value.toString().slice(1).toLowerCase()
  } else {
    return value
  }
})

Vue.filter('payment_method', function (value) {
  let status = 'No disponible'
  switch (value) {
    case 1:
      status = 'Efectivo'
      break
    case 2:
      status = 'Cheque'
      break
    case 3:
      status = 'Tarjeta de Crédito'
      break
    case 4:
      status = 'Depósito / Transferencia'
      break
    default:
  }
  return status
})
