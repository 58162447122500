import httpClient from './httpClient'

const END_POINT = '/api/client_attachments'

const getAllAttachments = (payload) => httpClient.get(END_POINT, { params: payload })
const deleteAttachment = (payload) => httpClient.delete(END_POINT + '/' + payload.id)

export {
  getAllAttachments,
  deleteAttachment
}
