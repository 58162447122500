import { getAllAttachments, getAllCategoryAttachments, deleteAttachment } from '@/api/attachment.api'

// State object
const initialState = () => ({
  modalCreate: false,
  record_attachments: [],
  categories_attachments: [
    {
      label: 'General',
      value: 'General'
    },
    {
      label: 'Parte Contraria',
      value: 'Parte Contraria'
    },
    {
      label: 'Instancia',
      value: 'Instancia'
    }
  ]
})

const state = initialState()

// Getter functions
const getters = {
  modalCreate (state) {
    return state.modalCreate
  },
  record_attachments (state) {
    return state.record_attachments
  },
  categories_attachments (state) {
    return state.categories_attachments
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async getRecordAttachments ({ commit }, payload) {
    try {
      const response = await getAllAttachments(payload)
      commit('SET_RECORD_ATTACHMENTS', response.data)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getCategoryAttachments ({ commit }) {
    try {
      const response = await getAllCategoryAttachments()
      commit('SET_CATEGORIES_ATTACHMENTS_DROPDOWN', response.data)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async delete ({ commit }, payload) {
    try {
      await deleteAttachment(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_RECORD_ATTACHMENTS (state, payload) {
    state.record_attachments = payload
  },
  SET_CATEGORIES_ATTACHMENTS_DROPDOWN (state, payload) {
    if (this.payload) {
      this._.forEach(payload, function (row) {
        if (!this._.some(state.categories_attachments, row)) {
          state.categories_attachments.push(row)
        }
      })
    }
  },
  TOGGLE_MODAL_CREATE (state) {
    state.modalCreate = !state.modalCreate
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
