import { getAllRecords, getRecord, getAllRecordsDropdown, createRecord, deleteRecord, updateRecord } from '@/api/record.api'

// State object
const initialState = () => ({
  modalCreate: false,
  modalUpdate: false,
  modalFileUpdate: false,
  records_dropdown: [],
  records: []
})

const state = initialState()

// Getter functions
const getters = {
  modalCreate (state) {
    return state.modalCreate
  },
  modalUpdate (state) {
    return state.modalUpdate
  },
  modal_file_update (state) {
    return state.modalFileUpdate
  },
  records_dropdown (state) {
    return state.records_dropdown
  },
  records (state) {
    return state.records
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async getRecords ({ commit }, payload) {
    try {
      const response = await getAllRecords(payload)
      commit('SET_RECORDS', response.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getRecordsDropdown ({ commit }, payload) {
    try {
      const response = await getAllRecordsDropdown(payload)
      commit('SET_RECORDS_DROPDOWN', response.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchRecord ({ commit }, payload) {
    try {
      const response = await getRecord(payload)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async create ({ commit }, payload) {
    try {
      const response = await createRecord(payload)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update ({ commit }, payload) {
    try {
      const response = await updateRecord(payload)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async delete ({ commit }, payload) {
    try {
      await deleteRecord(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_RECORDS (state, payload) {
    state.records = payload
  },
  SET_RECORDS_DROPDOWN (state, payload) {
    state.records_dropdown = payload
  },
  TOGGLE_MODAL_CREATE (state, payload) {
    if (payload) {
      state.modalCreate = payload
    } else {
      state.modalCreate = !state.modalCreate
    }
  },
  TOGGLE_MODAL_UPDATE (state, payload) {
    if (payload) {
      state.modalUpdate = payload
    } else {
      state.modalUpdate = !state.modalUpdate
    }
  },
  RECORD_TOGGLE_MODAL_FILEMANAGER (state, payload) {
    if (payload) {
      state.modalFileUpdate = payload
    } else {
      state.modalFileUpdate = !state.modalFileUpdate
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
