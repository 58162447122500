import { getAllClientsDropdown, getClientFromRecord, createClient, deleteClient, updateClient, restoreClient } from '@/api/client.api'

// State object
const initialState = () => ({
  modalCreate: false,
  modalUpdate: false,
  clients_dropdown: [],
  client_from_record: null
})

const state = initialState()

// Getter functions
const getters = {
  modalCreate (state) {
    return state.modalCreate
  },
  modalUpdate (state) {
    return state.modalUpdate
  },
  clients_dropdown (state) {
    return state.clients_dropdown
  },
  client_from_record (state) {
    return state.client_from_record
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async getClients ({ commit }) {
    try {
      const response = await getAllClientsDropdown()
      commit('SET_CLIENTS_DROPDOWN', response.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getClientRecord ({ commit }, payload) {
    try {
      const response = await getClientFromRecord(payload)
      commit('SET_CLIENT_FROM_RECORD', response.data)
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async create ({ commit }, payload) {
    try {
      await createClient(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update ({ commit }, payload) {
    try {
      await updateClient(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async delete ({ commit }, payload) {
    try {
      await deleteClient(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async restore ({ commit }, payload) {
    try {
      await restoreClient(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_CLIENTS_DROPDOWN (state, payload) {
    state.clients_dropdown = payload.map((row) => {
      return {
        code: row.id,
        label: row.commercial_name ? row.commercial_name : row.first_name + ' ' + row.last_name
      }
    })
  },
  SET_CLIENT_FROM_RECORD (state, payload) {
    state.clients_dropdown = [{
      code: payload.client.id,
      label: payload.client.commercial_name ? payload.client.commercial_name : payload.client.first_name + ' ' + payload.client.last_name
    }]
  },
  TOGGLE_MODAL_CREATE (state, payload) {
    if (payload) {
      state.modalCreate = payload
    } else {
      state.modalCreate = !state.modalCreate
    }
  },
  TOGGLE_MODAL_UPDATE (state, payload) {
    if (payload) {
      state.modalUpdate = payload
    } else {
      state.modalUpdate = !state.modalUpdate
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
