import { getAllCourtsDropdown, getAllSubjectsDropdown, createCourt, updateCourt } from '@/api/court.api'

// State object
const initialState = () => ({
  modalCreate: false,
  modalUpdate: false,
  subjects: [],
  courts_dropdown: []
})

const state = initialState()

// Getter functions
const getters = {
  modalCreate (state) {
    return state.modalCreate
  },
  modalUpdate (state) {
    return state.modalUpdate
  },
  subjects (state) {
    return state.subjects
  },
  courts_dropdown (state) {
    return state.courts_dropdown
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async create ({ commit }, payload) {
    try {
      await createCourt(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update ({ commit }, payload) {
    try {
      await updateCourt(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getSubjects ({ commit }) {
    try {
      const response = await getAllSubjectsDropdown()
      commit('SET_SUBJECTS', response.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getCourts ({ commit }, payload) {
    try {
      const response = await getAllCourtsDropdown(payload)
      commit('SET_COURTS_DROPDOWN', response.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_SUBJECTS (state, payload) {
    state.subjects = payload
  },
  SET_COURTS_DROPDOWN (state, payload) {
    state.courts_dropdown = payload
  },
  TOGGLE_MODAL_CREATE (state, payload) {
    if (payload) {
      state.modalCreate = payload
    } else {
      state.modalCreate = !state.modalCreate
    }
  },
  TOGGLE_MODAL_UPDATE (state, payload) {
    if (payload) {
      state.modalUpdate = payload
    } else {
      state.modalUpdate = !state.modalUpdate
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
