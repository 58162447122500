import httpClient from './httpClient'

const END_POINT = '/api/clients'

const getAllClients = () => httpClient.get(END_POINT)
const getAllClientsDropdown = () => httpClient.get(END_POINT + '/dropdown')
const getClientFromRecord = (payload) => httpClient.get(END_POINT + '/find/by_record/' + payload)
const createClient = (payload) => httpClient.post(END_POINT, payload)
const deleteClient = (payload) => httpClient.delete(END_POINT + '/' + payload.id)
const updateClient = (payload) => httpClient.put(END_POINT + '/' + payload.id, payload)
const restoreClient = (payload) => httpClient.post(END_POINT + '/' + payload.id + '/restore')

export {
  getAllClients,
  getAllClientsDropdown,
  getClientFromRecord,
  createClient,
  deleteClient,
  updateClient,
  restoreClient
}
