import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import createLogger from 'vuex/dist/logger'
Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules,
  state: {
    menu: false,
    isLoading: false
  },
  getters: {
    menuStatus (state) {
      return state.menu
    },
    isLoading (state) {
      return state.isLoading
    }
  },
  actions: {
    reset ({ commit }) {
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/RESET`)
      })
    },
    toggleMenu ({ commit }) {
      commit('TOGGLE_MENU')
    },
    toggleLoading ({ commit }) {
      commit('TOGGLE_LOADING')
    }
  },
  mutations: {
    TOGGLE_MENU (state, payload) {
      if (payload === 1) {
        state.menu = true
      }
      state.menu = !state.menu
    },
    TOGGLE_LOADING (state) {
      state.isLoading = !state.isLoading
    }
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
