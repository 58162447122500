import { getAllProviders, createProvider, updateProvider, deleteProvider, getProviders } from '@/api/provider.api'

// State object
const initialState = () => ({
  modalCreate: false,
  modalUpdate: false,
  providers_dropdown: false
})

const state = initialState()

// Getter functions
const getters = {
  modalCreate (state) {
    return state.modalCreate
  },
  modalUpdate (state) {
    return state.modalUpdate
  },
  providers_dropdown (state) {
    return state.providers_dropdown
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async getProvidersDropdown ({ commit }, payload) {
    try {
      const response = await getProviders(payload)
      commit('SET_PROVIDERS_DROPDOWN', response.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchProviders ({ commit }, payload) {
    try {
      const response = await getAllProviders(payload)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async create ({ commit }, payload) {
    try {
      const response = await createProvider(payload)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update ({ commit }, payload) {
    try {
      await updateProvider(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async delete ({ commit }, payload) {
    try {
      await deleteProvider(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  TOGGLE_MODAL_CREATE (state, payload) {
    if (payload) {
      state.modalCreate = payload
    } else {
      state.modalCreate = !state.modalCreate
    }
  },
  TOGGLE_MODAL_UPDATE (state, payload) {
    if (payload) {
      state.modalUpdate = payload
    } else {
      state.modalUpdate = !state.modalUpdate
    }
  },
  SET_PROVIDERS_DROPDOWN (state, payload) {
    state.providers_dropdown = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
