import httpClient from './httpClient'

const END_POINT = '/api/attachments'

const getAllAttachments = (payload) => httpClient.get(END_POINT, { params: payload })
const getAllCategoryAttachments = () => httpClient.get(END_POINT + '/get/dropdown')
const deleteAttachment = (payload) => httpClient.delete(END_POINT + '/' + payload.id)

export {
  getAllAttachments,
  getAllCategoryAttachments,
  deleteAttachment
}
