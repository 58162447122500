// State object
const initialState = () => ({
  textFilter: '',
  displaySearch: false
})

const state = initialState()

// Getter functions
const getters = {
  textFilter (state) {
    return state.textFilter
  },
  displaySearch (state) {
    return state.displaySearch
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_TEXT_FILTER (state, payload) {
    state.textFilter = payload
  },
  TOGGLE_SEARCH_BOX (state, payload) {
    state.displaySearch = !state.displaySearch
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
