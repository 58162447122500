import { getAllPermissions } from '@/api/permission.api'
import Vue from 'vue'

// State object
const initialState = () => ({
  permissions: null,
  accessPermissions: Vue.cookie.get('permissions') || ''
})

const state = initialState()

// Getter functions
const getters = {
  permissions (state) {
    return state.permissions
  },
  accessPermissions (state) {
    return state.accessPermissions.split(',')
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async getAll ({ commit }) {
    try {
      const response = await getAllPermissions()
      commit('SET_PERMISSIONS', response.data)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_PERMISSIONS (state, payload) {
    state.permissions = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
