import httpClient from './httpClient'

const END_POINT = '/api'

const initializeResource = () => httpClient.get('/sanctum/csrf-cookie')
const registerUser = (payload) => httpClient.post(END_POINT + '/register', { first_name: payload.first_name, last_name: payload.last_name, email: payload.email, password: payload.password, password_confirmation: payload.password_confirmation })
const loginUser = (payload) => httpClient.post(END_POINT + '/login', { email: payload.email, password: payload.password })
const logoutUser = () => httpClient.post(END_POINT + '/logout')
const createPasswordRecovery = (payload) => httpClient.post(END_POINT + '/password/recovery/create', { email: payload.email })
const resetPassword = (payload) => httpClient.post(END_POINT + '/password/recovery/reset', { email: payload.email, password: payload.password, password_confirmation: payload.password_confirmation, token: payload.token })

export {
  initializeResource,
  registerUser,
  loginUser,
  logoutUser,
  createPasswordRecovery,
  resetPassword
}
