import Vue from 'vue'
import VueCookie from 'vue-cookie'
import { initializeResource, registerUser, loginUser, logoutUser } from '@/api/authentication.api'

Vue.use(VueCookie)

// State object
const initialState = () => ({
  user: Vue.cookie.get('user') || '',
  permissions: Vue.cookie.get('permissions') || '',
  roles: Vue.cookie.get('roles') || ''
})

const state = initialState()

// Getter functions
const getters = {
  loggedIn (state) {
    return state.user
  },
  permissions (state) {
    return state.permissions
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async initialize () {
    try {
      await initializeResource()
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async register ({ commit }, payload) {
    try {
      const response = await registerUser(payload)
      commit('SET_USER', response.data)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async login ({ commit }, payload) {
    try {
      const response = await loginUser(payload)
      commit('SET_USER', response.data)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async logout ({ commit }) {
    try {
      const response = await logoutUser()
      commit('RESET')
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    Vue.cookie.delete('user')
    Vue.cookie.delete('permissions')
    Vue.cookie.delete('roles')
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_USER (state, payload) {
    state.user = payload.user
    state.permissions = payload.permissions
    state.roles = payload.roles
    Vue.cookie.set('user', payload.user, 180)
    Vue.cookie.set('permissions', payload.permissions, 180)
    Vue.cookie.set('roles', payload.roles, 180)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
