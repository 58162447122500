import { getAllUsersDropdown, getAllLawyersDropdown, createUser, updateUser, assignRole, getPermissions, pushSubscribeUser, pushUnsubscribeUser } from '@/api/user.api'

// State object
const initialState = () => ({
  users: null,
  modalCreate: false,
  modalUpdate: false,
  users_dropdown: [],
  lawyers_dropdown: []
})

const state = initialState()

// Getter functions
const getters = {
  users (state) {
    return state.users
  },
  modalCreate (state) {
    return state.modalCreate
  },
  modalUpdate (state) {
    return state.modalUpdate
  },
  users_dropdown (state) {
    return state.users_dropdown
  },
  lawyers_dropdown (state) {
    return state.lawyers_dropdown
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async getAll ({ commit }) {
    try {
      const response = await getAllUsersDropdown()
      commit('SET_USERS_DROPDOWN', response.data)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getAllLawyers ({ commit }, payload) {
    try {
      const response = await getAllLawyersDropdown(payload)
      commit('SET_LAWYERS_DROPDOWN', response.data)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async create ({ commit }, payload) {
    try {
      await createUser(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update ({ commit }, payload) {
    try {
      await updateUser(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async assignRole ({ commit }, payload) {
    try {
      await assignRole(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async checkSelfPermissions ({ commit }, payload) {
    try {
      await getPermissions(payload)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async subscribe ({ commit }, payload) {
    try {
      const response = await pushSubscribeUser(payload)
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async unsubscribe ({ commit }, payload) {
    try {
      const response = await pushUnsubscribeUser(payload)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_USERS_DROPDOWN (state, payload) {
    state.users_dropdown = payload.map((row) => {
      return {
        code: row.id,
        label: row.first_name + ' ' + row.last_name
      }
    })
  },
  SET_LAWYERS_DROPDOWN (state, payload) {
    state.lawyers_dropdown = payload.map((row) => {
      return {
        code: row.id,
        label: row.first_name + ' ' + row.last_name
      }
    })
  },
  TOGGLE_MODAL_CREATE (state, payload) {
    if (payload) {
      state.modalCreate = payload
    } else {
      state.modalCreate = !state.modalCreate
    }
  },
  TOGGLE_MODAL_UPDATE (state, payload) {
    if (payload) {
      state.modalUpdate = payload
    } else {
      state.modalUpdate = !state.modalUpdate
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
