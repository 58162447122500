import httpClient from './httpClient'

const END_POINT = '/api/instances'

const getAllInstances = () => httpClient.get(END_POINT)
const getAllInstancesDropdown = () => httpClient.get(END_POINT + '/dropdown')
const createInstance = (payload) => httpClient.post(END_POINT, payload)
const deleteInstance = (payload) => httpClient.delete(END_POINT + '/' + payload.id)
const updateInstance = (payload) => httpClient.put(END_POINT + '/' + payload.id, payload)

export {
  getAllInstances,
  getAllInstancesDropdown,
  createInstance,
  deleteInstance,
  updateInstance
}
