import httpClient from './httpClient'

const END_POINT = '/api/payments'

const getAllPayments = () => httpClient.get(END_POINT)
const getPayment = (payload) => httpClient.get(END_POINT + '/' + payload.id)
const createPayments = (payload) => httpClient.post(END_POINT, payload)
const updatePayments = (payload) => httpClient.put(END_POINT + '/' + payload.id, payload)
const deletePayments = (payload) => httpClient.delete(END_POINT + '/' + payload.id)

export {
  getAllPayments,
  getPayment,
  createPayments,
  updatePayments,
  deletePayments
}
