import { getFinances } from '@/api/report.api'

// State object
const initialState = () => ({
  finances: {
    invoices: '',
    expenses_no_invoiced: '',
    bills: '',
    expenses_invoiced: ''
  }
})

const state = initialState()

// Getter functions
const getters = {
  finances (state) {
    return state.finances
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async getFinancesReport ({ commit }, payload) {
    try {
      const response = await getFinances(payload)
      commit('SET_FINANCES', response.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_FINANCES (state, payload) {
    state.finances = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
