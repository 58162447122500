import httpClient from './httpClient'

const END_POINT = '/api/bills'

const getAllBills = () => httpClient.get(END_POINT)
const getBill = (payload) => httpClient.get(END_POINT + '/' + payload.id)
const createBills = (payload) => httpClient.post(END_POINT, payload)
const updateBills = (payload) => httpClient.put(END_POINT + '/' + payload.id, payload)
const deleteBills = (payload) => httpClient.delete(END_POINT + '/' + payload.id)

export {
  getAllBills,
  getBill,
  createBills,
  updateBills,
  deleteBills
}
