<template>
    <router-view/>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations({
      TOGGLE_MENU: 'TOGGLE_MENU'
    })
  },
  watch: {
    $route (to, from) {
      this.TOGGLE_MENU(1)
    }
  }
}
</script>
