import { getDashboard } from '@/api/dashboard.api'

// State object
const initialState = () => ({
  resume: {
    events: 0,
    clients: 0,
    records: 0,
    incoming: 0,
    payments: 0,
    expenses: 0
  }
})

const state = initialState()

// Getter functions
const getters = {
  resume (state) {
    return state.resume
  }
}

// Actions
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },
  async getResume ({ commit }, payload) {
    try {
      const response = await getDashboard(payload)
      commit('SET_DATA', response)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// Mutations
const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_DATA (state, payload) {
    state.resume = payload.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
