import httpClient from './httpClient'

const END_POINT = '/api/users'
const END_POINT_2 = '/api/push'

const getAllUsers = () => httpClient.get(END_POINT)
const getAllUsersDropdown = () => httpClient.get(END_POINT + '/users/dropdown')
const getAllLawyersDropdown = (payload) => httpClient.get(END_POINT + '/lawyers/dropdown', payload)
const createUser = (payload) => httpClient.post(END_POINT, payload)
const updateUser = (payload) => httpClient.put(END_POINT + '/' + payload.id, payload)
const deleteUser = (user) => httpClient.delete(END_POINT + '/' + user)
const profileUser = () => httpClient.get(END_POINT + '/my/profile')
const assignRole = (payload) => httpClient.post(END_POINT + '/' + payload.id + '/update/role/' + payload.role_id)
const getPermissions = (payload) => httpClient.post(END_POINT + '/' + payload.id + '/get/permissions')
const pushSubscribeUser = (payload) => httpClient.post(END_POINT_2 + '/subscribe', payload)
const pushUnsubscribeUser = (payload) => httpClient.post(END_POINT_2 + '/unsubscribe', payload)

export {
  getAllUsers,
  getAllUsersDropdown,
  getAllLawyersDropdown,
  createUser,
  updateUser,
  deleteUser,
  profileUser,
  assignRole,
  getPermissions,
  pushSubscribeUser,
  pushUnsubscribeUser
}
